const routes = [
  /** 养老预约 END */
  // 养老预约首页
  {
    path: "/futureHealth/elderlyCareAppointment",
    name: "elderlyCareAppointment",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureHealth/elderlyCareAppointment/index.vue",
      ], resolve),
  }, // 养老预约详情
  {
    path: "/futureHealth/elderlyCareAppointmentDetail",
    name: "elderlyCareAppointmentDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureHealth/elderlyCareAppointment/detail.vue",
      ], resolve),
  }, // 养老预约申请
  {
    path: "/futureHealth/elderlyCareAppointmentApply",
    name: "elderlyCareAppointmentApply",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureHealth/elderlyCareAppointment/apply.vue",
      ], resolve),
  }, // 养老预约申请
  {
    path: "/futureHealth/elderlyCareAppointmentComplete",
    name: "elderlyCareAppointmentComplete",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureHealth/elderlyCareAppointment/complete.vue",
      ], resolve),
  }, // 养老预约我的订单

  {
    path: "/futureHealth/elderlyCareAppointmentMyOrder",
    name: "elderlyCareAppointmentMyOrder",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureHealth/elderlyCareAppointment/myOrder.vue",
      ], resolve),
  }, // 养老预约审核订单

  {
    path: "/futureHealth/elderlyCareAppointmentAudioOrder",
    name: "elderlyCareAppointmentAudioOrder",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureHealth/elderlyCareAppointment/audioOrder.vue",
      ], resolve),
  }, // 养老预约订单详情

  {
    path: "/futureHealth/elderlyCareAppointmentOrderDetail",
    name: "elderlyCareAppointmentOrderDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureHealth/elderlyCareAppointment/orderDetail.vue",
      ], resolve),
  } /** 养老预约 END */,
  // 金钟健康心
  {
    path: "/futureHealth/jinzhongHealthyMind",
    name: "jinzhongHealthyMind",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureHealth/jinzhongHealthyMind/index.vue"], resolve),
  }, // 菜品详情
  {
    path: "/futureHealth/foodDetail",
    name: "foodDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureHealth/jinzhongHealthyMind/foodDetail.vue",
      ], resolve),
  }, // 身份认证
  {
    path: "/futureHealth/identityAuth",
    name: "identityAuth",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureHealth/jinzhongHealthyMind/identityAuth.vue",
      ], resolve),
  }, // 我的订单
  {
    path: "/futureHealth/myFoodOrderList",
    name: "myFoodOrderList",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureHealth/jinzhongHealthyMind/myFoodOrderList.vue",
      ], resolve),
  }, // 我的订单-管理员
  {
    path: "/futureHealth/foodOrderList",
    name: "foodOrderList",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureHealth/jinzhongHealthyMind/foodOrderList.vue",
      ], resolve),
  }, // 我的订单-详情
  {
    path: "/futureHealth/myFoodOrderDetail",
    name: "myFoodOrderDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureHealth/jinzhongHealthyMind/myFoodOrderDetail.vue",
      ], resolve),
  }, // 支付成功
  {
    path: "/futureHealth/deliverySuccess",
    name: "deliverySuccess",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureHealth/jinzhongHealthyMind/deliverySuccess.vue",
      ], resolve),
  }, // 选择就餐方式
  {
    path: "/futureHealth/chooseDiningMethod",
    name: "chooseDiningMethod",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureHealth/jinzhongHealthyMind/chooseDiningMethod.vue",
      ], resolve),
  }, // 订单结算
  {
    path: "/futureHealth/payFoodOrder",
    name: "payFoodOrder",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureHealth/jinzhongHealthyMind/payFoodOrder.vue",
      ], resolve),
  },
  //   运动打卡
  {
    path: "/futureHealth/health",
    name: "health",
    meta: {
      title: "",
    },
    component: (resolve) => require(["@/views/futureHealth/health"], resolve),
  },
  //   运动打卡列表
  {
    path: "/futureHealth/healthList",
    name: "healthList",
    meta: {
      title: " ",
    },
    component: (resolve) =>
      require(["@/views/futureHealth/health/healthList.vue"], resolve),
  },
  //   运动打卡详情
  {
    path: "/futureHealth/healthDetail",
    name: "healthDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureHealth/health/healthDetail.vue"], resolve),
  },
  //   运动打卡审核列表
  {
    path: "/futureHealth/verifySport",
    name: "verifySport",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureHealth/verifySport/index.vue"], resolve),
  },
  //   运动打卡审核详情
  {
    path: "/futureHealth/verifySportDetail",
    name: "verifySportDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureHealth/verifySport/verifySportDetail.vue",
      ], resolve),
  },
  // 老年大学-首页
  {
    path: "/futureHealth/universityForTheElderly",
    name: "universityForTheElderly",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureHealth/universityForTheElderly/index.vue",
      ], resolve),
  },
  // 老年大学-师生介绍
  {
    path: "/futureHealth/teachersAndStudentsIntroduce",
    name: "teachersAndStudentsIntroduce",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureHealth/universityForTheElderly/teachersAndStudentsIntroduce.vue",
      ], resolve),
  },
  // 老年大学-师生介绍-更多
  {
    path: "/futureHealth/teachersAndStudentsMoreList",
    name: "teachersAndStudentsMoreList",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureHealth/universityForTheElderly/teachersAndStudentsMoreList.vue",
      ], resolve),
  },
  // 老年大学-优秀作品
  {
    path: "/futureHealth/worksOfExcellence",
    name: "worksOfExcellence",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureHealth/universityForTheElderly/worksOfExcellence.vue",
      ], resolve),
  },
  // 老年大学-优秀作品-详情
  {
    path: "/futureHealth/worksOfExcellenceDetail",
    name: "worksOfExcellenceDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureHealth/universityForTheElderly/worksOfExcellenceDetail.vue",
      ], resolve),
  },
  // 老年大学-我的预约
  {
    path: "/futureHealth/myAppointment",
    name: "myAppointment",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureHealth/universityForTheElderly/myAppointment.vue",
      ], resolve),
  },
  // 老年大学-我的预约-详情
  {
    path: "/futureHealth/myAppointmentDetail",
    name: "myAppointmentDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureHealth/universityForTheElderly/myAppointmentDetail.vue",
      ], resolve),
  },
  // 老年大学-预约成功
  {
    path: "/futureHealth/appointmentSucceeded",
    name: "appointmentSucceeded",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureHealth/universityForTheElderly/appointmentSucceeded.vue",
      ], resolve),
  },
  // 老年大学-课程预约
  {
    path: "/futureHealth/courseAppointmentDetail",
    name: "courseAppointmentDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureHealth/universityForTheElderly/courseAppointmentDetail.vue",
      ], resolve),
  },
];
export default routes;
