<template>
  <div class="home">
    <!-- <van-button type="danger">危险按钮</van-button>
 <div>{{ path }}</div>
 <br />
<div>{{ data }}</div>
 <div>结果:{{ result }}</div>
 <div>获取微信步数{{ result1 }}</div>
 <div>获取所需参数（用户信息）{{ result2 }}</div>
 <div>获取经纬度{{ result3 }}</div> -->
  </div>
</template>

<script>
import store from "@/store";
import { gloabalCount } from "@/utils/common.js";
export default {
  name: "Home",
  data() {
    return {
      data: "",
      search: "",
      path: "",
      dataType: "",
      userInfo: {},
      result: null,
      result1: null,
      result2: null,
      result3: null,
      isLocal: false, //是否本地调试
    };
  },
  created() {
    this.path = window.location.href;
    this.search = window.location.search;
    const query = this.getSearchData(window.location.search);
    this.data = query;
    sessionStorage.setItem("appId", query.appId);
  },
  async mounted() {
    if (this.isLocal) {
      this.localTest(); //本地调试方法
    } else {
      await this.getInfo(this.data);
      if (
        this.userInfo.extraParameter.indexOf("latitude") != -1 &&
        this.userInfo.extraParameter.indexOf("longitude") != -1
      ) {
        // 需要携带经纬度的页面跳转
        setTimeout(() => {
          this.getLocationData();
        }, 800);
      } else if (this.userInfo.extraParameter.indexOf("step") != -1) {
        // 需要携带微信步数的页面跳转
        this.getQueryWechatStep();
      } else {
        // 什么都不需要的页面跳转
        this.noThingJump();
        console.log("跳转成功~~~");
      }
    }
  },
  methods: {
    // 本地调试方法
    localTest() {
      let userInfo = {
        assetId: "39",
        openId: "",
        defOrgId: "",
        routerName: "ldleTransaction",
        appId: 23, // 生产
        houseId: "P1C2A2S1D1V7B16R1001",
        communityId: "P1C2A2S1D1V7",
        userId: "1584507026212392962",
        tenantId: "P1C2A2S1D1",
        mobile: "18752782297", // 研发 // houseId: "P1C2A2S1D1V1B1U1R101", // communityId: "P1C2A2S1D1V1", // userId: "1598624100260069378", // tenantId: "P1C2A2S1D1", // mobile: "18516155069",
      };
      this.extraOneMore(userInfo);
      this.$router.replace({
        name: userInfo.routerName,
        query: {
          appId: userInfo.appId,
        },
      }); // 社区和伙人-talent 1 （达人风采列表页 partnerStyleList ） // 随手拍-clapCasually 2 // 湾头创业-futureCreateWork-home 3 // 众创空间-rentPlace 4 // 湾头报修-reportForRepait 5 // 湾头超话-topicList 6 // 每日签到-daySign 7 // 湾头托幼-entrustChild 8 // 安全智护-safetyShield 9 // 运动打卡-healthList 10 // 数字社区-digitalCommunity 11 // 湾头问卷-questionnaireList 12 // 湾头党建-partyBuilding 13 // 湾头防疫-epidemicPrevent 14 // 投票表决-voteList 16 // 活动广场-applyActivity 17 // 湾头社群-association 18 // 垃圾分类-rubbishSort 19 // 看电视听广播-watchTvListenRadio 20 // 老年大学-universityForTheElderly 22 // 管理后台令牌-administrativeToken 23 // 场地预约-areaAppoint 24
    }, // 什么都不需要的页面跳转
    noThingJump() {
      // 跳转活动详情
      if (
        this.userInfo.extraType == "activity" &&
        this.userInfo.appData &&
        JSON.parse(this.userInfo.appData).id
      ) {
        this.$router.replace({
          name: "activityDetail",
          query: {
            activityId: JSON.parse(this.userInfo.appData).id,
          },
        });
      } else {
        this.$router.replace({
          name: this.userInfo.routerName,
        });
      }
    }, // 获取微信步数
    getQueryWechatStep() {
      let url = `${window.ORIGIN}/gateway/jb-future/jbfuture/queryWechatStep`;
      let params = {
        appId: this.data.appId,
        accountId: this.userInfo.miniUserId,
      };
      this.$axios.post(`${url}`, params).then((res) => {
        this.result1 = res;
        if (res.code == 200) {
          let list = `${res.data.beforeYesterday},${res.data.yesterday},${res.data.today}`;
          this.$router.replace({
            name: this.userInfo.routerName,
            query: {
              stepList: list,
            },
          });
        }
      });
    }, // 获取经纬度方法
    getLocationData() {
      window.xy.ready(() => {
        window.xy.getLocation((res) => {
          if (res) {
            this.result3 = res;
            this.$router.replace({
              name: this.userInfo.routerName,
              query: {
                latitude: res.latitude,
                longitude: res.longitude,
              },
            });
          }
        });
      });
    }, // 获取所需参数（用户信息）
    async getInfo(data) {
      let params = data;
      let url = `${window.ORIGIN}/gateway/jb-future/jbfuture/getCaseInfoByTicket`;
      let res = await this.$axios.post(`${url}`, params, {
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
        },
      });
      this.result2 = res;
      if (res.code == 200) {
        this.userInfo = res.data;
        let user = {
          assetId: res.data.assetId,
          communityId: res.data.communityId,
          houseId: res.data.houseId,
          orgId: res.data.orgId,
          tenantId: res.data.tenantId,
          userId: res.data.userId,
          mobile: res.data.mobile,
        };
        this.extraOneMore(user);
        this.selectRouteName();
        gloabalCount("", this.dataType, 1);
      }
    }, // home页面埋点id
    selectRouteName() {
      switch (this.userInfo.routerName) {
        case "talent":
          this.dataType = 101;
          break;
        case "clapCasually":
          this.dataType = 102;
          break;
        case "futureCreateWork-home":
          this.dataType = 103;
          break;
        case "rentPlace":
          this.dataType = 104;
          break;
        case "reportForRepair":
          this.dataType = 105;
          break;
        case "topicList":
          this.dataType = 106;
          break;
        case "daySign":
          this.dataType = 107;
          break;
        case "entrustChild":
          this.dataType = 108;
          break;
        case "safetyShield":
          this.dataType = 109;
          break;
        case "healthList":
          this.dataType = 110;
          break;
        case "digitalCommunity":
          this.dataType = 111;
          break;
        case "questionnaireList":
          this.dataType = 112;
          break;
        case "partyBuilding":
          this.dataType = 113;
          break;
        case "epidemicPrevent":
          this.dataType = 114;
          break;
        case "voteList":
          this.dataType = 116;
          break;
        case "applyActivity":
          this.dataType = 117;
          break;
        case "association":
          this.dataType = 118;
          break;
        case "rubbishSort":
          this.dataType = 119;
          break;
        case "watchTvListenRadio":
          this.dataType = 120;
          break;
        default:
          this.dataType = 101;
          break;
      }
    }, // 更新vuex参数
    extraOneMore(user) {
      sessionStorage.setItem("userInfo", JSON.stringify(user));
      store.commit("setAssetId", user.assetId);
      store.commit("setMobile", user.mobile);
      store.commit("setOpenId", user.openId);
      store.commit("setDefOrgId", user.defOrgId);
      store.commit("setHouseId", user.houseId);
      store.commit("setCommunityId", user.communityId);
      store.commit("setUserId", user.userId);
      store.commit("setTenantId", user.tenantId);
    }, // location.search 转换为 对象
    getSearchData(search) {
      let obj = {};
      let arr = search.slice(1).split("&");
      arr.forEach((item) => {
        let newArr = item.split("=");
        obj[newArr[0]] = newArr[1];
      });
      return obj;
    },
  },
};
</script>

<style lang="less" scoped>
.home {
  word-break: break-all;
}
</style>
