<template>
  <van-swipe
    class="v-swipe"
    :autoplay="autoplay"
    :duration="duration"
    :indicator-color="indicatorColor"
  >
    <!-- <div class="oSwiper" v-for="(item, index) in swiperData" :key="index"> -->
    <van-swipe-item v-for="(item, index) in swiperData" :key="index">
      <slot :list="swiperData" :row="item">
        <img
          class="van-swipe-item--image"
          :src="item"
          alt=""
          @click="showImage(index)"
        />
      </slot>
    </van-swipe-item>
    <!-- </div> -->
  </van-swipe>
</template>

<script>
import Vue from "vue";
import { Swipe, SwipeItem, ImagePreview } from "vant";

// Vue.use(Swipe);
// Vue.use(SwipeItem);
export default {
  components: {
    vanSwipe: Swipe,
    vanSwipeItem: SwipeItem,
  },
  props: {
    // 自动轮播间隔，单位为 ms 使用该属性最好带有.sync后缀
    autoplay: {
      type: [Number, String],
      default: "-",
    },
    // 动画时长，单位为 ms
    duration: {
      type: [Number, String],
      default: 500,
    },
    // 指示器颜色
    indicatorColor: {
      type: String,
      default: "white",
    },
    // 图片地址合集
    swiperData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    showImage(i) {
      // 预览关闭轮播图滚动
      // this.$emit("update:autoplay", "-");
      ImagePreview({
        images: this.swiperData,
        //开始的图片位置
        startPosition: i,
        overlayStyle: {
          background: "#000",
        },
        // //关闭预览图时-该图为轮播图起始图
        // onClose: (res) => {
        //   //轮播图的方法，跳到对应的图片
        //   this.$refs.vantSwiper.swipeTo(res.index, {
        //     immediate: true, //关闭轮播切换效果
        //   });
        //   this.autoplay = 2000;
        // },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.v-swipe {
  width: 100%;
  height: 100%;
  .van-swipe-item--image {
    width: 100%;
    height: 100%;
    vertical-align: middle;
    object-fit: cover;
  }
}
</style>
