<template>
  <div class="v-popup">
    <van-popup
      v-model="model"
      closeable
      :position="position"
      :style="{ height: height }"
    >
      <slot></slot>
    </van-popup>
  </div>
</template>

<script>
import Vue from "vue";
import { Popup } from "vant";

Vue.use(Popup);
export default {
  watch: {
    value(newValue) {
      this.model = newValue;
    },
    model(newValue) {
      this.$emit("input", newValue);
    },
  },
  components: {},
  props: {
    title: {
      type: String,
      default: "",
    },
    height: {
      type: String,
      default: "60%",
    },
    position: {
      type: String,
      default: "bottom",
    },
    showCancelButton: {
      type: Boolean,
      default: true,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      model: this.value,
    };
  },
  methods: {
    confirm() {
      this.$emit("confirm");
    },
  },
};
</script>

<style></style>
