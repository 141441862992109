<template>
  <div class="v-comments" :style="{ 'z-index': `${zIndex}` }" v-show="show">
    <div class="overlay" @click.stop="close"></div>
    <div class="content" :class="{ noFoot: !isShowFoot }">
      <div class="picList" v-if="picList.length > 0 && picListFlag">
        <div
          class="item"
          v-for="(item, index) in picList2"
          :key="index"
          @click="showImgClick(index)"
        >
          <img
            class="delIcon"
            @click.stop="deleteImg(index)"
            src="./img/delete.png"
            alt=""
          />
          <img class="img" :src="item" alt="" />
        </div>
      </div>
      <van-field
        ref="vanField"
        :value="value"
        :rows="rows"
        autosize
        :label="label"
        :type="type"
        :maxlength="maxlength"
        :placeholder="placeholder"
        :disabled="disabled"
        show-word-limit
        @input="changeValue"
      />
      <input
        class="hide_file"
        ref="leftFile"
        id="upload"
        type="file"
        @change="getFile($event)"
        accept="image/*"
        style="display: none"
      />
      <footer>
        <slot name="footer">
          <div
            :class="
              picList.length < maxCount && showUploadImgBtn
                ? 'btns-ground'
                : 'btns-ground1'
            "
          >
            <div
              class="send btn"
              v-if="picList.length < maxCount && showUploadImgBtn"
              @click.stop="clickFile"
            >
              上传图片
            </div>
            <div class="send btn" @click.stop="send">发送</div>
          </div>
        </slot>
      </footer>
    </div>
    <v-picList
      :startPosition="startPosition"
      v-model="showImg"
      :imgList="picList"
    >
    </v-picList>
  </div>
</template>

<script>
export default {
  name: "v-comments",
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    // uploadUrl: {
    //   type: String,
    //   default: "/gateway/blade-resource/oss/endpoint/put-file",
    // },
    // 是否开启上传图片功能
    showUploadImgBtn: {
      type: Boolean,
      default: false,
    },
    // 上传图片最大数
    maxCount: {
      type: Number,
      default: 9,
    },
    value: {
      type: String,
      required: true,
    },
    // 控制是否显示
    show: {
      type: Boolean,
      default: false,
    },
    // 文本域层级
    rows: {
      type: String,
      default: "5",
    },
    // 定位层级
    zIndex: {
      type: [String, Number],
      default: 100,
    },
    // 提示文字
    placeholder: {
      type: String,
      default: "请输入",
    },
    // 输入框类型
    type: {
      type: String,
      default: "textarea",
    },
    // 左侧文字内容
    label: {
      type: String,
      default: "",
    },
    // 最大长度
    maxlength: {
      type: String,
      default: "200",
    },
    // 1/200 的脚注是否显示
    isShowFoot: {
      type: Boolean,
      default: false,
    },
    // 是否在点击发送按钮后自动关闭
    isAutoColse: {
      type: Boolean,
      default: false,
    },
    autosize: {
      type: [Boolean, Object],
      default: () => {
        return {
          maxHeight: "",
          minHeight: "",
        };
      },
    },
    // 是否禁用
    disabled: Boolean,
  },
  data() {
    return {
      showImg: false,
      startPosition: 0,
      flag: false,
      picList: [],
      picList2: [],
      picListFlag: false,
    };
  },
  watch: {
    show(newValue) {
      if (newValue) {
        return;
      }
      this.close();
    },
  },
  mounted() {},
  methods: {
    showImgClick(index) {
      this.showImg = true;
      this.startPosition = index;
    },
    getFile(e) {
      console.log(e);
      let file = e.target.files[0];
      var formData = new FormData(); //创建form对象
      formData.append("file", file); //通过append向form对象添加数据
      if (file) {
        let uploadUrl = `${window.ORIGIN}/gateway/blade-resource/oss/endpoint/put-file`;
        this.$axios.post(`${uploadUrl}`, formData).then((res) => {
          if (res.code == 200) {
            this.picListFlag = false;
            this.picList.push(res.data.link);
            this.picList2.push(this.$handleImg(200, 200, res.data.link));

            this.picListFlag = true;
          }
        });
      }
    },
    deleteImg(index) {
      this.picListFlag = false;
      this.picList.splice(index, 1);
      this.picList2.splice(index, 1);

      this.picListFlag = true;
    },
    clickFile() {
      this.$refs.leftFile.click();
    },
    getFocus() {
      this.$refs.vanField.focus();
    },
    changeValue(val) {
      this.$emit("input", val);
    },
    send() {
      this.$emit("acceptSend", this.value, this.picList.join(","));
      // 是否自动
      if (this.isAutoColse) {
        this.close();
      }
    },
    close() {
      this.$emit("update:show", false);
      // 1. 弹窗关闭后，清空输入框的值
      this.$emit("input", "");
      this.picList = [];
      this.picList2 = [];
    },
  },
};
</script>

<style lang="less" scoped>
.v-comments {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  .picList {
    display: flex;
    flex-wrap: wrap;
    .item {
      width: 200px;
      height: 200px;
      margin-right: 20px;
      margin-bottom: 20px;
      position: relative;
      background-color: black;
      .delIcon {
        width: 30px;
        height: 30px;
        position: absolute;
        top: -15px;
        right: -15px;
        z-index: 1;
      }
      .img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  .overlay {
    height: 100vh;
    width: 100%;
    background: #00000088;
  }
  .content {
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 32px 32px 32px 32px;
    background: #fff;
    &.noFoot {
      ::v-deep .van-field {
        .van-cell__value {
          .van-field__word-limit {
            display: none;
          }
        }
      }
    }
    ::v-deep .van-field {
      // padding: 32px 32px 16px 32px;
      padding: 24px;
      background: #f5f5f5;
      border-radius: 8px;
      overflow: hidden;
    }
    footer {
      margin-top: 16px;
      .btns-ground {
        display: flex;
        justify-content: space-between;
        .btn {
          background: #007eff;
          color: #fff;
          font-size: 28px;
          padding: 6px 28px;
          border-radius: 8px;
          line-height: 44px;
        }
      }
      .btns-ground1 {
        display: flex;
        justify-content: flex-end;
        .btn {
          background: #007eff;
          color: #fff;
          font-size: 28px;
          padding: 6px 28px;
          border-radius: 8px;
          line-height: 44px;
        }
      }
    }
  }
}
</style>
