import { getHashParam } from "@/utils/utils.js";
import store from "@/store";
//扩展函数
function containsStr(str, val) {
  return str && val && str.indexOf(val) >= 0;
}
String.prototype.ossResize = function (resizeWidth, resizeHeight) {
  if (!resizeWidth || resizeWidth === 0) {
    resizeWidth = 500;
  }
  if (!resizeHeight) {
    resizeHeight = resizeWidth;
  }

  let path = this;
  if (path.length === 0) {
    return path;
  }

  if (path.indexOf(",") >= 0) {
    path = path.split(",")[0];
  }

  let OSS_RESIZE = "?x-oss-process=image/resize,m_fill,w_";
  if (
    !containsStr(path, "?") &&
    (containsStr(path, "http://") ||
      containsStr(path, "https://") ||
      containsStr(path, "ftp://"))
  ) {
    path += OSS_RESIZE + resizeWidth + ",h_" + resizeHeight;
  }
  console.log(path);
  return path;
};

// 获取地址栏参数
let user;
let userInfoTemp = sessionStorage.getItem("userInfo");
if (userInfoTemp) {
  user = JSON.parse(userInfoTemp);
} else {
  let communityId = getHashParam("communityId");
  let userId = getHashParam("userId");
  let houseId = getHashParam("houseId");
  let defOrgId = getHashParam("defOrgId");
  let tenantId = getHashParam("tenantId");
  let openId = getHashParam("openId");
  let assetId = getHashParam("assetId");
  let mobile = getHashParam("mobile");
  let userInfo = {
    communityId: communityId,
    userId: userId,
    houseId: houseId,
    defOrgId: defOrgId,
    tenantId: tenantId,
    openId: openId,
    assetId: assetId,
    mobile: mobile,
  };
  user = userInfo;
  sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
}
store.commit("setAssetId", user.assetId);
store.commit("setOpenId", user.openId);
store.commit("setDefOrgId", user.defOrgId);
store.commit("setHouseId", user.houseId);
store.commit("setCommunityId", user.communityId);
store.commit("setUserId", user.userId);
store.commit("setTenantId", user.tenantId);
store.commit("setMobile", user.mobile);
